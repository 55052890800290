/**
 * Google Maps
 **/

var mapIcon = '/assets/dist/img/map-marker.png';
var mapIconHover = '/assets/dist/img/map-marker-hover.png';

var map;
var bounds = new google.maps.LatLngBounds();
var markers = [];
var infowindow = new google.maps.InfoWindow({
    maxWidth: 320
});

function initialize() {
    var mapOptions = {
        scrollwheel: false,
        center: new google.maps.LatLng(52.186, 5.271191),
        zoom: 6,
        styles: mapStyles
    };

    map = new google.maps.Map(document.getElementById('providers-map'), mapOptions);

    $.each(locations, function (index, location) {
        var position = {lat: parseFloat(location['lat']), lng: parseFloat(location['lng'])};

        // Create marker
        createMarker(location, position);

        // Recalculate map bounds
        bounds.extend(position);
        map.fitBounds(bounds);
    });
}

function createMarker(data, position) {
    var marker = new google.maps.Marker({
        position: position,
        map: map,
        icon: mapIcon,
        animation: google.maps.Animation.DROP
    });

    markers.push({
        marker: marker
    });

    var html = '<span style="font-size: 1.25em; font-weight: bold;">' + data['title'] + '</span>' +
        '<br /><em>' + data['address'] + '</em>';

    google.maps.event.addListener(marker, 'mouseover', function () {
        marker.setIcon(mapIconHover);
        infowindow.setContent(html);
        infowindow.open(map, marker);
    });

    google.maps.event.addListener(marker, 'mouseout', function () {
        marker.setIcon(mapIcon);
        infowindow.close();
    });

    google.maps.event.addListener(marker, 'click', function () {
        window.open(data['pageUri']);
    });
}

if ($('#providers-map').length) {
    google.maps.event.addDomListener(window, 'load', initialize);
}

/**
 * Fit locations in window resize
 **/

function fitLocationMapBounds() {
    map.fitBounds(bounds);
}

/**
 * Google Maps Settings
 **/

var mapStyles = [
    {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
            {
                "lightness": 100
            },
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#C6E2FF"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#C5E3BF"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#D1D1B8"
            }
        ]
    }
];